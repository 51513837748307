import React, { useContext, useEffect, useState, useRef } from 'react'
import Layout from '../layout'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Box, CssBaseline } from '@material-ui/core'
import ACC_THEME from '../../themes'
import ResourceCard from '@system/resource-card'
import classNames from 'classnames'
import PageContext from '@context'
import isInBrowser from '@helpers/is-in-browser'
import PageContainer from '@system/page-container'
import { imgMaxWidths } from '@variables/global-variables'

const useStyles = makeStyles({
  body: {
    marginTop: '-188px',
    position: 'relative',
  },
  outerWebinarContainer: {
    width: '100%',
    margin: '0 auto',
    padding: '0 41.6px',
    maxWidth: '1056px',
    [ACC_THEME.breakpoints.down('xs')]: {
      width: '90%',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  webinarGallery: {
    margin: '0 auto',
    display: 'flex',
    padding: '25px 0px',
    flexWrap: 'wrap',
    maxWidth: '100%',
    textAlign: 'left',
    justifyContent: 'space-around',
  },
  webinarCards: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  displayCards: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 8px',
    width: '33.33333333%',
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '50%',
    },
    [ACC_THEME.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
})

const WebinarsIndex = (props) => {
  const pageContext = useContext(PageContext)
  const webinarsItem = useRef()

  const { body, filters, headerImage, metaData } = props.blok
  const classes = useStyles()

  const [isVisible, setVisibility] = useState(null)
  const [numberOfLoadedWebinars, setNumberOfLoadedWebinars] = useState(12)

  const updateHiddenFieldsValue = (value) => {
    setVisibility(value)
  }

  const watch = 'WATCH'
  const register = 'REGISTER'

  const upcomingWebinars =
    pageContext.webinars &&
    pageContext.webinars
      .filter(
        (webinar) =>
          webinar.content.webinarVideo &&
          webinar.content.webinarVideo.length === 0
      )
      .slice()
      .sort(function (firstObj, secondObj) {
        let dateFirstObj = new Date(firstObj.created_at)
        let dateSecondObj = new Date(secondObj.created_at)
        return dateSecondObj - dateFirstObj
      })

  const onDemandWebinars =
    pageContext.webinars &&
    pageContext.webinars
      .filter(
        (webinar) =>
          webinar.content.webinarVideo &&
          webinar.content.webinarVideo.length > 0
      )
      .slice()
      .sort(function (firstObj, secondObj) {
        let dateFirstObj = new Date(firstObj.created_at)
        let dateSecondObj = new Date(secondObj.created_at)
        return dateSecondObj - dateFirstObj
      })

  const allWebinarStories = [...upcomingWebinars, ...onDemandWebinars].map(
    (webinar) => ({ full_slug: webinar.full_slug, ...webinar.content })
  )

  const getWebinarsHyperLink = (webinar) => {
    return !!webinar.webinarVideo && webinar.webinarVideo.length > 0
      ? watch
      : register
  }

  useEffect(() => {
    if (
      isInBrowser &&
      allWebinarStories &&
      numberOfLoadedWebinars <= allWebinarStories.length
    ) {
      window.addEventListener('scroll', loadWebinarsOnScroll)
    }
  })

  const loadWebinarsOnScroll = () => {
    const webinarsBottomRect =
      webinarsItem &&
      webinarsItem.current &&
      webinarsItem.current.getBoundingClientRect().bottom
    if (webinarsBottomRect <= window.innerHeight) {
      setNumberOfLoadedWebinars(numberOfLoadedWebinars + 12)
      window.removeEventListener('scroll', loadWebinarsOnScroll)
    }
  }

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <Box ref={webinarsItem}>
          {!!headerImage && <Box>{renderBloks(headerImage)}</Box>}
          <PageContainer>
            <Box className={classes.body}>
              {!!body && renderBloks(body)}
              {!!filters &&
                renderBloks(filters, {
                  isWebinarsModule: true,
                  updateHiddenFieldsValue,
                  isWebinarsIndexView: isVisible,
                })}
              <Box className={classes.outerWebinarContainer}>
                <Box className={classes.webinarGallery}>
                  <Box className={classes.webinarCards}>
                    {!isVisible &&
                      !!allWebinarStories &&
                      allWebinarStories
                        .slice(0, numberOfLoadedWebinars)
                        .map((webinar) => {
                          const webinarsHyperLink =
                            getWebinarsHyperLink(webinar)
                          return (
                            <Box
                              className={classNames(classes.displayCards)}
                              key={webinar._uid}
                            >
                              <ResourceCard
                                blok={{
                                  ...webinar,
                                  textToHyperlink: webinarsHyperLink || 'Read',
                                  isWebinarsModule: true,
                                  description: webinar.subtitle,
                                  link: webinar.full_slug,
                                }}
                                imgMaxWidth={imgMaxWidths.resourceCard}
                              />
                            </Box>
                          )
                        })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </PageContainer>
        </Box>
      </ThemeProvider>
    </Layout>
  )
}

export default WebinarsIndex
